@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');
body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(132, 125, 125, 0.09);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.base_loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MuiDrawer-docked {
  flex: 0 0 0 !important;
}

.makeStyles-root-80 {
  display: block !important;
  width: 100vw;
}

.makeStyles-root-1 {
  display: block !important;
  padding: 10rem;
  width: 100vw;
}

textarea {
  outline-color: #a8774c;
  overflow: auto;
  transition: 1s;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon,
.MuiDataGrid-root .MuiDataGrid-menuOpen {
  visibility: hidden;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: #a8774c !important;
}

.MuiDataGrid-columnHeaderTitle {
  color: white;
}

.MuiDataGrid-iconButtonContainer .MuiIconButton-label {
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-label {
  color: white;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #ede4db;
}

.MuiDataGrid-row:hover {
  background-color: black;
}

.MuiDataGrid-root .MuiDataGrid-menuIcon {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: none !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #265979;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f7cb5;
  border-radius: 20px;
}

.search_shadow_mobile {
  box-shadow: 0 21px 36px rgba(0, 0, 0, 0.045);
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}