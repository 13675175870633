@import url(https://fonts.googleapis.com/css2?family=Cairo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amiri&display=swap);
body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(132, 125, 125, 0.09);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.base_loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MuiDrawer-docked {
  flex: 0 0 !important;
}

.makeStyles-root-80 {
  display: block !important;
  width: 100vw;
}

.makeStyles-root-1 {
  display: block !important;
  padding: 10rem;
  width: 100vw;
}

textarea {
  outline-color: #a8774c;
  overflow: auto;
  transition: 1s;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon,
.MuiDataGrid-root .MuiDataGrid-menuOpen {
  visibility: hidden;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: #a8774c !important;
}

.MuiDataGrid-columnHeaderTitle {
  color: white;
}

.MuiDataGrid-iconButtonContainer .MuiIconButton-label {
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-label {
  color: white;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #ede4db;
}

.MuiDataGrid-row:hover {
  background-color: black;
}

.MuiDataGrid-root .MuiDataGrid-menuIcon {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: none !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #265979;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f7cb5;
  border-radius: 20px;
}

.search_shadow_mobile {
  box-shadow: 0 21px 36px rgba(0, 0, 0, 0.045);
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.side_bar::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.side_bar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px #a8774c;
	border-radius: 10px;
}

/* Handle */
.side_bar::-webkit-scrollbar-thumb {
	background: #a8774c;
	border-radius: 10px;
}

.search_shadow_mobile {
	box-shadow: 0 21px 36px rgba(0, 0, 0, 0.045);
}

.red_ {
	background-color: #e53935;
}

.blue_ {
	background-color: #1f7cb5;
}

.yellow_ {
	background-color: #de9d3c;
}

.brown_ {
	background-color: #a8774c;
}

.mt {
	padding: 2rem 0;
	background-color: rgba(132, 125, 125, 0.09);
}

a {
	text-decoration: none !important;
	color: #1f7cb5;
}

.image-container {
	position: relative;
	overflow: hidden;
	max-width: 300px;
	z-index: 1;
}
.image-container::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #e5e5e5;
	opacity: 0;
	transition: 0.5s ease all;
}
.image-container:hover .img {
	transform: scale(1.1);
}

.image-container:hover::after {
	opacity: 0.1;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis.white div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis.blue div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #1f7cb5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


@keyframes ldio-22w060nii9o-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(30px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(30px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-22w060nii9o {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(30px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ldio-22w060nii9o div {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 57.3px;
  left: 19.099999999999998px;
}
.ldio-22w060nii9o.dark div:nth-child(1) {
  background: #1F7CB5;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}
.ldio-22w060nii9o.dark div:nth-child(2) {
  background: #c97041;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: 0s;
}
.ldio-22w060nii9o.dark div:nth-child(3) {
  background: #1F7CB5;
  animation: ldio-22w060nii9o-o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}

.ldio-22w060nii9o.light div:nth-child(1) {
  background: #7dc0ea;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}
.ldio-22w060nii9o div:nth-child(2) {
  background: #eb996d;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: 0s;
}
.ldio-22w060nii9o div:nth-child(3) {
  background: #7dc0ea;
  animation: ldio-22w060nii9o-o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}
.loadingio-spinner-dual-ball-4lehzrw8vc4 {
  width: 90px;
  height: 90px;
  display: inline-block;
  overflow: hidden;
}
.ldio-22w060nii9o {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-22w060nii9o div {
  box-sizing: content-box;
}
