.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis.white div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis.blue div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #1f7cb5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


@keyframes ldio-22w060nii9o-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(30px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(30px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-22w060nii9o {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(30px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ldio-22w060nii9o div {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 57.3px;
  left: 19.099999999999998px;
}
.ldio-22w060nii9o.dark div:nth-child(1) {
  background: #1F7CB5;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}
.ldio-22w060nii9o.dark div:nth-child(2) {
  background: #c97041;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: 0s;
}
.ldio-22w060nii9o.dark div:nth-child(3) {
  background: #1F7CB5;
  animation: ldio-22w060nii9o-o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}

.ldio-22w060nii9o.light div:nth-child(1) {
  background: #7dc0ea;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}
.ldio-22w060nii9o div:nth-child(2) {
  background: #eb996d;
  animation: ldio-22w060nii9o 0.9615384615384615s linear infinite;
  animation-delay: 0s;
}
.ldio-22w060nii9o div:nth-child(3) {
  background: #7dc0ea;
  animation: ldio-22w060nii9o-o 0.9615384615384615s linear infinite;
  animation-delay: -0.4807692307692307s;
}
.loadingio-spinner-dual-ball-4lehzrw8vc4 {
  width: 90px;
  height: 90px;
  display: inline-block;
  overflow: hidden;
}
.ldio-22w060nii9o {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-22w060nii9o div {
  box-sizing: content-box;
}