.side_bar::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.side_bar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px #a8774c;
	border-radius: 10px;
}

/* Handle */
.side_bar::-webkit-scrollbar-thumb {
	background: #a8774c;
	border-radius: 10px;
}

.search_shadow_mobile {
	box-shadow: 0 21px 36px rgba(0, 0, 0, 0.045);
}

.red_ {
	background-color: #e53935;
}

.blue_ {
	background-color: #1f7cb5;
}

.yellow_ {
	background-color: #de9d3c;
}

.brown_ {
	background-color: #a8774c;
}

.mt {
	padding: 2rem 0;
	background-color: rgba(132, 125, 125, 0.09);
}

a {
	text-decoration: none !important;
	color: #1f7cb5;
}

.image-container {
	position: relative;
	overflow: hidden;
	max-width: 300px;
	z-index: 1;
}
.image-container::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #e5e5e5;
	opacity: 0;
	transition: 0.5s ease all;
}
.image-container:hover .img {
	transform: scale(1.1);
}

.image-container:hover::after {
	opacity: 0.1;
}
